<template>
  <div class="question questionare-inner backgroundColor1" >
    <Header
      class="header-innerworks"
    />
    <div class="content_header header-initial" :class="{'started-page-styles': startedPage
    || crowdSurveyPage}">
      <StartedPage
        v-if="startedPage"
      />
      <SurveyCrowdPage
        v-else-if="crowdSurveyPage"
        :questions="questions"
        :userName="userNameForCrowdSurvey"
      />
      <SurveyStep
        v-else-if="step1"
        :questions="questions"
        :startFromQuestion="startFromQuestion"
      />
      <RegistrationStep
        v-else-if="step2"
      />
      <LoginStep
        v-else-if="step3"
      />
      <SelfResultStep
        v-else-if="step4"
      />
      <Page5
        v-if="step5"
        :questions="questions"
      />
      <Page6
        v-if="step6"
        :questions="questions"
      />
      <Page7
        v-if="step7"
      />
      <Page8
        v-if="step8"
      />
      <Page9
        v-if="step9"
      />
      <Page10
        v-if="step10"
      />
      <Page11
        v-if="step11"
      />
      <Page12
        v-if="step12"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import StartedPage from '@modules/Step/StartedPage.vue';
import SurveyCrowdPage from '@modules/Step/SurveyCrowdStep.vue';
import checkbox from '../assets/checkbox_fill.svg';
import Header from '../components/Header/Header.vue';
import SurveyStep from '../modules/Step/SurveyStep.vue';
import RegistrationStep from '../modules/Step/RegistrationStep.vue';
import LoginStep from '../modules/Step/LoginStep.vue';
import SelfResultStep from '../modules/Step/SelfResultStep.vue';
import Page5 from '../modules/Step/Page5.vue';
import Page6 from '../modules/Step/Page6.vue';
import Page7 from '../modules/Step/Page7.vue';
import Page8 from '../modules/Step/Page8.vue';
import Page9 from '../modules/Step/Page9.vue';
import Page10 from '../modules/Step/Page10.vue';
import Page11 from '../modules/Step/Page11.vue';
import Page12 from '../modules/Step/Page12.vue';

export default {
  name: 'Questions',
  data: () => ({
    checkbox,
    questions: null,
    startFromQuestion: null,
    currentStep: 1,
    allStepCount: 1,
    selectedAnswer: null,
    formData: {},
    show: {
      questions: true,
      attentions: false,
    },
    userNameForCrowdSurvey: '',
    redirectLink: 'invintation-report',
    loading: false,
    showStartCrowdPage: false,
  }),
  components: {
    SurveyCrowdPage,
    StartedPage,
    SurveyStep,
    RegistrationStep,
    LoginStep,
    SelfResultStep,
    Page5,
    Page6,
    Page7,
    Page8,
    Page9,
    Page10,
    Page11,
    Page12,
    Header,
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      startedPage: 'showComponent/startedPage',
      step1: 'showComponent/step1',
      step2: 'showComponent/step2',
      step3: 'showComponent/step3',
      step4: 'showComponent/step4',
      step5: 'showComponent/step5',
      step6: 'showComponent/step6',
      step7: 'showComponent/step7',
      step8: 'showComponent/step8',
      step9: 'showComponent/step9',
      step10: 'showComponent/step10',
      step11: 'showComponent/step11',
      step12: 'showComponent/step12',
      crowdSurveyPage: 'showComponent/crowdSurveyStep',
      backgroundColor: 'showComponent/backgroundColor',
    }),
    getDataByStep() {
      if (!this.questions) return null;

      if (this.currentStep === 1) {
        this.startColleaguesTest();
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.questions[this.currentStep - 1].text = this.questions[this.currentStep - 1].text
        .replace(/{{/, '<span class="highlighting-name">').replace(/}}/, '</span>');
      return this.questions[this.currentStep - 1];
    },
    isPersonalityTest() {
      return this.$route.meta.invitationType === process.env.QUESTIONNAIRE_ID;
    },
    isAuth() {
      return this.getProfile.token;
    },
    // isFreeVersionWebSite() {
    //   return isFreeVersion();
    // },
  },
  created() {
    this.fetchData();
    const url = window.location;
    const checkHref = url.href.search('invitation');
    if (checkHref > -1) {
      this.$store.dispatch('showComponent/startedCrowd');
      // this.$store.commit('showComponent/setShowHeader4', true);
      // this.$store.commit('showComponent/setShowHeader1', true);
      // this.$store.commit('showComponent/setShowQuestions', false);
    }
  },
  methods: {
    completedColleaguesTest() {
      this.$gtag.event('Click test', {
        [window.location.pathname.split('/').pop()]: 'Complete test', // eslint-disable-line
        'value': 1,                                                             // eslint-disable-line
      });
    },
    startColleaguesTest() {
      this.$gtag.event('Click test', {
        [window.location.pathname.split('/').pop()]: 'Start test', // eslint-disable-line
        'value': 1,                                                          // eslint-disable-line
      });
    },
    fetchData() {
      if (this.isPersonalityTest) {
        return this.fetchInvitationQuestionnaire(this.$route.params.id);
      }
      return this.fetchQuestions();
    },
    fetchQuestions() {
      this.$api.questionnaire.fetchQuestionnaireNew(localStorage.getItem('uniqueId'))
        .then((data) => {
          this.setResponseData(data);
        });
    },
    fetchInvitationQuestionnaire(id) {
      this.$api.questionnaire.fetchInvitationQuestionnaire(id)
        .then((data) => {
          this.setResponseData(data);
        }).catch(() => {
          this.$router.push({ name: 'main' });
        });
    },
    setResponseData(data) {
      this.questions = data.questionList2;
      this.userNameForCrowdSurvey = data.userName;
      this.startFromQuestion = data.startFromQuestionId;
      this.setLengthStep(data.questionList2);
    },
    setLengthStep(data) {
      this.allStepCount = data.length;
    },
    selectedAnswers(question) {
      this.selectedAnswer = question;
    },
    setStep(step) {
      this.currentStep = step;
    },
    setAnswer(questionId) {
      this.formData[this.getDataByStep.qid] = questionId;
    },
    async saveAnswerByPersonalityTest() {
      if (!localStorage.getItem('uniqueId')) {
        localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
          * Math.floor(Math.random() * Date.now()) * Math.random())}`);
      }

      let requestParam = '?option=';
      if (this.$route.query.option) {
        requestParam += this.$route.query.option;
      } else {
        requestParam = '';
      }

      return this.$store.dispatch('invitation/setPersonalityTest',
        {
          formData: this.formData,
          id: this.$route.params.id,
          uniqueId: localStorage.getItem('uniqueId'),
          requestParam,
        }).then(() => {
        if (this.isAuth) {
          return this.$router.push({ name: 'questionnaire-management' });
        }

        return this.toggleShowContent();
      }).catch(() => {
        if (this.isAuth) {
          return this.$router.push({ name: 'questionnaire-management' });
        }
        return this.$router.push({
          name: 'main',
        });
      });
    },
    setRedirectAuth() {
      this.$store.dispatch('auth/setRedirectAuth', 'questionnaire-management');
    },
    toggleShowContent() {
      this.show.questions = !this.show.questions;
      this.show.attentions = !this.show.attentions;
    },
    async saveAnswer() {
      this.completedColleaguesTest();
      if (this.isPersonalityTest) {
        return this.saveAnswerByPersonalityTest();
      }

      if (!this.isAuth) {
        if (!localStorage.getItem('uniqueId')) {
          localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
            * Math.floor(Math.random() * Date.now()) * Math.random())}`);
        }

        return this.$api.questionnaire.saveAnswerFreeVersionNew(this.formData, localStorage.getItem('uniqueId'))
          .then(() => {
            this.$router.push({ name: '/questionnaire2' });
          }).catch(() => {
            this.$router.push({ name: '/questionnaire2' });
          });
      }


      return this.$api.questionnaire.saveAnswer(this.formData)
        .then(() => {
          const profile = {
            ...this.getProfile,
            completedQuestionnaires: [...this.getProfile.completedQuestionnaires,
              process.env.QUESTIONNAIRE_ID],
          };

          this.$store.dispatch('auth/updateProfile', profile).then(() => {
            this.$router.replace(this.getRedirectAuth);
          });
          this.$router.push({ name: 'questionnaire2' });
        });
    },
    // eslint-disable-next-line func-names
    nextStep: debounce(async function () {
      const nextStep = this.currentStep + 1;

      if (!this.selectedAnswer) return;

      this.setAnswer(this.selectedAnswer);
      this.selectedAnswer = null;

      if (nextStep <= this.allStepCount) {
        this.setStep(this.currentStep + 1);
      } else {
        this.loading = true;
        await this.saveAnswer();
        this.loading = false;
      }
    }, 700),
    selectAndNextStep(answer) {
      this.selectedAnswers(answer);
      this.nextStep();
    },
  },
};
</script>

<style lang="scss">
.questions-attentions{
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 0 24px;
  transform: translate(-50%, -50%);
  .text{
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
/*.started-page-styles {*/
/*  height: 100% !important;*/
/*}*/
.highlighting-name {
  text-transform: uppercase;
  color: red;
  font-weight: bold;
}
.questions {
  .block {
    border: 1px solid #ccc;
    margin: 0 auto;
  }
}
.header-initial {
  background-color: #F3F4F6;
  margin: -2px 0 -2px 0;
  max-width: 480px !important;
  align-self: center;
  height: calc(100% - 72px);
}
  .header-innerworks {
    /*margin-top: 10px;*/
  }
  .backgroundColor1 {
    background-color: #F3F4F6;
  }
  .backgroundColor2 {
    background-color: #F3F4F6;
  }
  .questionare-inner {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
