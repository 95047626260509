<template>
  <v-dialog
    v-model="dialog"
    width="480"
    content-class="custom-own-feedback-password"
    persistent
  >
    <v-card color="#F3F4F6">
      <v-card-title class="red-text text-h5 font-weight-bold dialog-own-feedback-title">
        <v-spacer/>
        Error
        <v-spacer/>
      </v-card-title>
      <v-card-text >
        <v-spacer/>
        <div class="dialog-own-feedback-content">
         {{message}}
        </div>
        <v-spacer/>
      </v-card-text>
      <button @click="redirectToLogin" class="button-own-feedback">OK</button>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'OwnFeedbackModal',
  data: () => ({
    dialog: true,
  }),
  props: {
    showingModal: {
      type: Boolean,
    },
    message: {
      type: String,
    },
  },
  methods: {
    redirectToLogin() {
      this.$router.push({
        name: 'main',
      });
    },
  },
  watch: {
    showingModal: {
      immediate: true,
      handler(val) {
        this.dialog = val;
      },
    },
    dialog(value) {
      this.$emit('show-modal', value);
    },
  },
};
</script>

<style lang="scss">
  .custom-own-feedback-password {
    max-height: 100% !important;
    margin: 0;
    overflow: hidden;
    font-family: $newDefaultFont;
    border-radius: 30px;
    text-align: center;
  }
  .dialog-own-feedback-title {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #320959;
  }
  .dialog-own-feedback-content {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #222222;
    margin-top: 15px;
  }
  .button-own-feedback {
    background-color: #7811C9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: white;
  }
</style>
