<template>
  <div class="step">
      <div class="container">
        <ResetPassword />
      </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ResetPassword from '../../views/ResetPassword.vue';

export default {
  name: 'Page3',
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ResetPassword,
  },
  computed: {
    ...mapGetters({
      showRegisterForm: 'showComponent/showRegisterForm',
    }),
  },
  methods: {
    login() {
      this.$router.push('login');
    },
  },
};
</script>
<style scoped>
/*.step {*/
/*  margin-left: 25px;*/
/*  margin-right: 25px;*/
/*}*/
.back-to-sigin {
  display: flex;
  justify-content: center;
}
.page-login {
  padding-left: 3px;
  color: #1976d2;
  cursor: pointer;
}
  .container {
    background-color: transparent;
  }
</style>
