<template>
  <div class="step">
      <div class="container">
        <h1>Privacy Policy</h1>
        <Content>
          <v-card class="overflow-hidden">
            <v-sheet
              id="scrolling-techniques-7"
              class="overflow-y-auto"
              max-height="400"
            >
              <v-container style="height: 1500px;">
                Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Qui soluta quam natus expedita
                quas autem, explicabo nam laborum.
                Eius reiciendis quas aperiam tenetur alias.
                Sunt corporis rem recusandae ipsam veniam.
              </v-container>
            </v-sheet>
          </v-card>
          <button
            class="button button_w-100 button_theme-default
            button_size-m button_disabled privacy-policy"
          >
            Ok
          </button>
        </Content>
      </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
// import ResetPassword from '../../views/ResetPassword.vue';

export default {
  name: 'Page12',
  data() {
    return {
      dialog: true,
    };
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    // ResetPassword,
  },
  computed: {
    ...mapGetters({
      showRegisterForm: 'showComponent/showRegisterForm',
    }),
  },
  methods: {
    closeConfirmModal() {
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
/*.step {*/
/*  margin-left: 25px;*/
/*  margin-right: 25px;*/
/*}*/
.button-set-account {
  width: 150px;
}
.privacy-policy {
  margin-top: 20px;
}
</style>
