<template>
  <div>
    <!-- <p
      class="header-intial__1">
      Get your initial assessment  and in 2 minutes
      and start your journey now ...
    </p> -->
    <NewQuestion
        :questions="questions"
      />
      <div class="questions container">
        <!-- <LoadingAllScreen :is-loading.sync="loading" :scroll-lock="false"/> -->
          <BaseShowImg
            :text="textButtonProcess"
            :src="user1"
          />
          <FrequentlyAskedQuestions
          />
          <Login />
      </div>
  </div>
</template>
<script>
import Login from '../../views/Login.vue';
import NewQuestion from '../Questions/index.vue';
import FrequentlyAskedQuestions from '../FrequentlyAskedQuestions/index.vue';
import user1 from '../../assets/user_1_journey.png';

export default {
  name: 'Page5',
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      user1,
      textButtonProcess: 'How does it work ?',
      // textButtonResult: 'What do the result show ?',
    };
  },
  components: {
    Login,
    NewQuestion,
    FrequentlyAskedQuestions,
  },
};
</script>
<style scoped>
.header-intial__1 {
    height: 107px;
    width: 271px;
    border: 1px solid;
    background: #F3F4F6;
    font-family: 'Montserrat';
    font-size: 18px;
    padding-top: 15px;
    margin-top: 20px;
  }
</style>
