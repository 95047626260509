<template>
  <Content class="started-page">
    <div class="content-start-page">
       <div class="heading">Before we start...</div>
       <div class="info-text">Once you mark a score, it cannot be undone.</div>
       <div class="info-text">Try out the slider below until you are comfortable with
            how it works</div>
       <div class="info-text font-weight-bold">Press and hold the slider to move it.
            Once you release, you have 2 seconds to change “</div>
    </div>
    <div class="question-slider">
      <QuestionSlider :answer="answer"
                      @update:answer="updateAnswer"
                      :clickedOnSlider="clickedOnSlider"
                      @selected-question="onSelectedQuestion"
                      @click-slider="clickToSlider"
                      @cancel-next-question="cancelNextQuestion"/>
    </div>
    <button @click="redirectToQuestionnaire" class="start-question-button">
      Let’s Get Started</button>
  </Content>
</template>

<script>
import sliderInfo from '@assets/slider-info.svg';
import QuestionSlider from '@components/Slider/QuestionSlider.vue';
import { debounce } from 'lodash';
import userBehave from '@constants/userBehave';

export default {
  name: 'StatedPage',
  components: { QuestionSlider },
  data: () => ({
    sliderInfo,
    answer: 0,
    debouncedHandler: null,
    clickedOnSlider: false,
  }),
  methods: {
    redirectToQuestionnaire() {
      this.$api.userBehaveSite.saveSiteEvent(userBehave.letsGetsStarted);
      this.$store.dispatch('showComponent/gotToStepInitial');
    },
    onSelectedQuestion() {
      this.debouncedHandler = debounce(() => {
        this.answer = 0;
        this.clickedOnSlider = false;
      }, 2000);
      return this.debouncedHandler();
    },
    updateAnswer(answer) {
      this.answer = answer;
    },
    clickToSlider() {
      this.clickedOnSlider = true;
    },
    cancelNextQuestion() {
      if (this.debouncedHandler) {
        this.debouncedHandler.cancel();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .slider-info-img {
    margin: 50px 25px 30px;
  }
  .content-start-page {
    margin-left: 27px;
    display: flex;
    flex-direction: column;
    margin-top: 55px;
    margin-right: 27px;
  }
  .info-text {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 22px;
    color: #646464;
  }
  .heading {
    margin-bottom: 25px;
    font-family: $newDefaultFont;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #320959;
  }
  .started-page {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .text-assesment {
    font-family: $newDefaultFont;
    text-align: center;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 10px;
  }
  @media (max-height: 400px) {
    .start-question-button {
      height: 40px !important;
    }
  }
  .start-question-button {
    background: #7811C9;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 480px;
    height: 80px;
  }
  @media (max-height: 700px) {
    .content-start-page {
      margin-top: 7px !important;
    }
    .slider-info-img {
      margin-top: 0 !important;
    }
  }
  .question-slider {
    margin-top: 100px;
    margin-bottom: 80px;
  }

</style>
