<template>
  <div class="step">
      <div class="container">
        <Content>
          <v-row justify="center">
            <v-dialog
              v-model="dialog"
              max-width="500"
            >
              <v-card>
                <v-card-title class="text-h5">
                </v-card-title>
                <v-card-text class="modal-content-consequences">
                  <div>
                    The password you entered is incorrect, please try again.
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    @click="closeConfirmModal"
                    color="#BC88E4"
                    class="button-cancle-dialog button-color button-set-account"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </Content>
      </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Page11',
  data() {
    return {
      dialog: true,
    };
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    // ResetPassword,
  },
  computed: {
    ...mapGetters({
      showRegisterForm: 'showComponent/showRegisterForm',
    }),
  },
  methods: {
    closeConfirmModal() {
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
/*.step {*/
/*  margin-left: 25px;*/
/*  margin-right: 25px;*/
/*}*/
.button-set-account {
  width: 150px;
}
</style>
