<template>
  <div class="loader">
    <figure>
      <img :src="loader" alt="loader"/>
      <figcaption class="text-content">
        Getting your results <span class="text-bold">ready!</span>
      </figcaption>
    </figure>
  </div>
</template>

<script>
import loader from '@assets/loader.svg';

export default {
  name: 'LoaderGetResult',
  data: () => ({
    loader,
  }),
};
</script>

<style lang="scss" scoped>
  .loader {
    text-align: center;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .text-content {
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    color: #320959;
  }
  .text-bold {
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    color: #320959;
  }
</style>
