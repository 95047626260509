<template>
  <div class="question">
    <div class="sub-title d-flex justify-space-between text-sm-center">
                <span class="st-disagree designation">Strongly<br/>
                  Disagree</span>
      <span class="st-agree designation">Strongly<br/>
                  Agree</span>
    </div>
    <v-slider
      v-model="answerCurrent"
      max="5"
      min="-5"
      step="0.5"
      height="85"
      hide-details
      thumb-color="#FF5151"
      thumb-label="always"
      :class="`tick-size`"
      @end="onSelectedQuestion"
      @mousedown="clickToSlider"
      @start="cancelNextQuestion"
    >
      <template v-slot:thumb-label="{ value }">
        {{ clickedOnSlider ? value.toFixed(1) : value }}
      </template>
    </v-slider>
  </div>
</template>

<script>
export default {
  name: 'QuestionSlider',
  props: {
    answer: {
      type: Number,
    },
    clickedOnSlider: {
      type: Boolean,
    },
  },
  computed: {
    answerCurrent: {
      get() {
        return this.answer;
      },
      set(value) {
        this.$emit('update:answer', value);
      },
    },
  },
  methods: {
    onSelectedQuestion(answer) {
      this.$emit('selected-question', answer);
    },
    clickToSlider() {
      this.$emit('click-slider');
    },
    cancelNextQuestion() {
      this.$emit('cancel-next-question');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.question {
  height: 100%;
  padding: 0 38px;
  .v-slider__thumb {
    left: 0;
    box-shadow: 4px 4px 24px #FF5151;
    -webkit-box-shadow: 4px 4px 24px #FF5151;
    transform: translate(-50%, -50%);

    &::before,
    &::after {
      display: none;
    }
  }

  .v-slider--horizontal .v-slider__thumb-label > * {
    -webkit-transform: none !important;
    transform: none !important;
  }

  .v-slider__thumb-label {
    font-family: $newDefaultFont;
    font-size: 20px;
    line-height: 36px;
    height: 55px !important;
    width: 52px !important;
    bottom: 120px;
    font-weight: bold;
    color: #ffffff;
    background-image: url("../../assets/question-arrow.svg");
    transform: translateY(-20%) translateY(-12px) translateX(-50%) rotate(0deg) !important;
    background-color: transparent !important;
    border-radius: 0;
  }

  .designation {
    color: $txtColorNewDesignMain;
    background: #ffffff;
    padding: 8px;
    border-radius: 20% / 50%;
    font-size: 11px;
    line-height: 12px;
    position: relative;
    top: 54px;
  }

  .st-agree {
    left: 24px;
  }

  .st-disagree {
    right: 24px;
  }

  .v-slider__thumb-label > span {
    transform: rotate(30deg) !important;
  }

  .v-slider__track-container {
    height: 80px;
    background-image: url("../../assets/markup_answer.svg");
    background-position: center;
    top: -40px;
    background-size: 100%;

    .v-slider__track-background,
    .v-slider__track-fill {
      display: none;
    }
  }

  .tick-size {
    .v-slider__thumb,
    .v-slider__thumb-label-container {
      width: 40px;
      height: 40px !important;
    }
  }
}
</style>
