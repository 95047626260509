<template>
    <NewQuestion
      :questions="questions"
      :crowd-survey="true"
      v-if="showStartPage"
    />
    <StartedPageCrowd
      :userName="userName"
      @start-survey="startSurvey"
      v-else/>
</template>
<script>
import StartedPageCrowd from '@components/StartSurvey/StartedPageСrowd.vue';
import NewQuestion from '../Questions/index.vue';
import user1 from '../../assets/user_1_journey.png';

export default {
  name: 'SurveyCrowdPage',
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    userName: {
      type: String,
    },
  },
  data() {
    return {
      user1,
      textButtonProcess: 'How does it work ?',
      showStartPage: false,
      // textButtonResult: 'What do the result show ?',
    };
  },
  methods: {
    startSurvey() {
      this.showStartPage = true;
      this.$store.dispatch('showComponent/crowdSurveyPage');
    },
  },
  components: {
    NewQuestion,
    StartedPageCrowd,
  },
};
</script>
<style scoped>
  .header-intial__1 {
    height: 107px;
    width: 271px;
    border: 1px solid;
    background: #D7B8EF;
    font-family: 'Montserrat';
    font-size: 18px;
    padding-top: 15px;
    margin-top: 20px;
    margin: auto;
    margin-bottom: 25px;
    text-align: center;
  }
</style>
