<template>
  <div class="step">
      <div class="container">
        <Login />
      </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Login from '../../views/Login.vue';

export default {
  name: 'Page3',
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Login,
  },
  computed: {
    ...mapGetters({
      showRegisterForm: 'showComponent/showRegisterForm',
    }),
  },
};
</script>
<style scoped>
/*.step {*/
/*  margin-left: 25px;*/
/*  margin-right: 25px;*/
/*}*/
</style>
