<template>
  <div class="step">
      <div class="container-self-result">
        <SelfAnswer
        />
      </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SelfAnswer from '../SelfAnswer/index.vue';
import resulGraphics from '../../assets/result_graphics.png';
import user1 from '../../assets/user_1_journey.png';

export default {
  name: 'Page4',
  data() {
    return {
      textButtonProcess: 'How does it work?',
      textButtonResult: 'What do the result show?',
      user1,
      resulGraphics,
    };
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    SelfAnswer,
  },
  computed: {
    ...mapGetters({
      showRegisterForm: 'showComponent/showRegisterForm',
    }),
  },
};
</script>
<style scoped>
.step {
  height: 100%;
}
.container-self-result {
  height: 100%;
}
</style>
