<template>
  <div class="step">
    <NewQuestion
      :questions="questions"
    />
    <div class="container">
      <ExpandAll
      />
      <SelfAnswer
      />
      <BaseShowImg
        :text="textButtonProcess"
        :src="user1"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ExpandAll from '../ExpandAll/index.vue';
import SelfAnswer from '../SelfAnswer/index.vue';
import NewQuestion from '../Questions/index.vue';
import user1 from '../../assets/user_1_journey.png';


export default {
  name: 'Page6',
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      user1,
      textButtonProcess: 'How does it work ?',
    };
  },
  components: {
    ExpandAll,
    SelfAnswer,
    NewQuestion,
    // RegistrationPage,
  },
  computed: {
    ...mapGetters({
      showRegisterForm: 'showComponent/showRegisterForm',
    }),
  },
};
</script>
<style scoped>
/* .step {
  margin-left: 25px;
  margin-right: 25px;
} */
</style>
