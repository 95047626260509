<template>
  <div class="step">
      <div class="container-self-result">
        <RegistrationPage
          v-if="showRegisterForm"
        />
        <SelfAnswer v-else/>
      </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import RegistrationPage from '@components/Onboarding/RegistrationPage.vue';
import SelfAnswer from '../SelfAnswer/index.vue';
import user1 from '../../assets/user_1_journey.png';

export default {
  name: 'Page2',
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      user1,
      textButtonProcess: 'How does it work ?',
      // textButtonResult: 'What do the result show ?',
    };
  },
  components: {
    RegistrationPage,
    SelfAnswer,
  },
  computed: {
    ...mapGetters({
      showRegisterForm: 'showComponent/showRegisterForm',
    }),
  },
};
</script>
<style scoped>
.step {
  height: 100%;
}
.container-self-result {
  height: 100%;
}
</style>
