<template>
  <Content class="started-page">
    <div class="start-crowd-content">
      <figure class="heading">
        <img class="logo-img" :src="logo" alt="logo-innerworks">
        <figcaption class="text-center">Personality Assessment</figcaption>
      </figure>
      <div class="start-text">
        <div class="sub-title-assesment">{{userName}} has invited you to
          take a personality assessment about them </div>
        <div class="text-assesment">This should take about 5 mins and all
          the answers are completely confidential.</div>
      </div>
    </div>
    <button @click="redirectToQuestionnaire" class="start-question-button">
      Let’s Get Started</button>
  </Content>
</template>

<script>
import logo from '@assets/logo.svg';
import userBehave from '@constants/userBehave';

export default {
  name: 'StatedPage',
  data: () => ({
    logo,
  }),
  props: {
    userName: {
      type: String,
    },
  },
  mounted() {
    this.$api.userBehaveSite.saveSiteEvent(userBehave.invitationPage);
  },
  methods: {
    redirectToQuestionnaire() {
      this.$api.userBehaveSite.saveSiteEvent(userBehave.letsGetsStartedCrowd);
      this.$emit('start-survey', true);
    },
  },
};
</script>

<style lang="scss" scoped>
  .logo-img {
    height: 80px;
    width: 80px;
  }
  .start-crowd-content {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 106px
  }
  @media (max-height: 570px) {
    .start-crowd-content {
      top: 7px
    }
  }
  @media (max-height: 670px) and (orientation:landscape) {
    .start-text {
      margin-top: -7px !important;
    }
    .sub-title-assesment {
      font-size: 22px !important;
    }
    .text-assesment {
      font-size: 18px !important;
      margin-bottom: 10px !important;
    }
    .heading {
      font-size: 24px !important;
    }
    .logo-img {
      height: 40px;
      width: 40px;
    }
  }
  .sub-title-assesment {
    text-align: center;
    font-family: $newDefaultFont;
    font-size: 28px;
    line-height: 32px;
    color: #7811C9;
    margin-bottom: 10px;

  }
  .heading {
    font-family: $newDefaultFont;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    color: #320959;
    text-align: center;
  }
  .start-text {
    max-width: 338px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
  }
  .started-page {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .text-assesment {
    font-family: $newDefaultFont;
    text-align: center;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 10px;
  }
  @media (max-height: 400px) {
    .start-question-button {
      height: 40px !important;
    }
  }
  .start-question-button {
    background: #7811C9;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 480px;
    height: 80px;
  }
</style>
