<template>
  <div class="step">
      <div class="container">
        <Content>
          <h1 class="h4 text-center mb-6">Thank you</h1>
          <h5 class="text-center mb-6">If the e-mail you have entered exist
            in our system,we will send a link to
            reset your password in a few seconds
          </h5>
          <div class="form-group form-group_submit">
          <button
            class="button button_w-100 button_theme-default button_size-m button_disabled"
          >
            Back to Login
          </button>
        </div>
        </Content>
      </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Page8',
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
  },
  computed: {
    ...mapGetters({
      showRegisterForm: 'showComponent/showRegisterForm',
    }),
  },
};
</script>
<style scoped>
</style>
