<template>
  <div>
    <NewQuestion
      :questions="questions"
      :start-from-question="startFromQuestion"
    />
  </div>
</template>
<script>
import NewQuestion from '../Questions/index.vue';
import user1 from '../../assets/user_1_journey.png';

export default {
  name: 'Page1',
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    startFromQuestion: {
      type: String,
    },
  },
  data() {
    return {
      user1,
      textButtonProcess: 'How does it work ?',
      // textButtonResult: 'What do the result show ?',
    };
  },
  components: {
    NewQuestion,
  },
};
</script>
<style scoped>
.header-intial__1 {
    height: 107px;
    width: 271px;
    border: 1px solid;
    background: #D7B8EF;
    font-family: 'Montserrat';
    font-size: 18px;
    padding-top: 15px;
    margin-top: 20px;
    margin: auto;
    margin-bottom: 25px;
    text-align: center;
  }
</style>
