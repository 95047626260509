<template>
  <div>
    <OwnFeedbackModal
      :message="errorMessage"
      :showing-modal="isOpenOwnFeedBackErrorModal"
      @show-modal="closeWarningModalModal($event)"/>
    <div v-if="currentQuestion" class="question-content">
      <QuestionProgressBar :current-index="currentIndex" :last-question-number="questions.length"/>
      <Content class="question">
        <template v-if="completedResult">
          <Question :question="currentQuestion.text"/>
          <div class="question__select-result">
            <div class="pt-9">
              <QuestionSlider
                @update:answer="updateAnswer"
                :clickedOnSlider="clickedOnSlider"
                :answer="answer"
                @selected-question="onSelectedQuestion"
                @click-slider="clickToSlider"
                @cancel-next-question="cancelNextQuestion"/>
            </div>
            <div class="info-use" v-if="currentIndex < 1">
              <div>
                <img src="../../assets/arrow-up.svg" alt="arrow-up"/>
              </div>
              <div class="info-text">Drag the slider left<br/> or right to rate</div>
            </div>
          </div>
        </template>
        <LoaderGetResult v-else>
        </LoaderGetResult>
      </Content>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import QuestionProgressBar from '@components/ProgressBar/QuestionProgressBar.vue';
import LoaderGetResult from '@components/Loader/LoaderGetResult.vue';
import OwnFeedbackModal from '@components/Modals/OwnFeedbackModal.vue';
import { mapGetters } from 'vuex';
import QuestionSlider from '@components/Slider/QuestionSlider.vue';
import userBehave from '@constants/userBehave';
import Question from './Question.vue';

export default {
  components: {
    QuestionSlider,
    OwnFeedbackModal,
    Question,
    QuestionProgressBar,
    LoaderGetResult,
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    crowdSurvey: {
      type: Boolean,
    },
    startFromQuestion: {
      type: String,
    },
  },
  data() {
    return {
      currentQuestion: null,
      currentIndex: 0,
      answer: 0,
      formData: {},
      completedResult: true,
      clickedOnSlider: false,
      debouncedHandler: null,
      isOpenOwnFeedBackErrorModal: false,
      errorMessage: '',
    };
  },
  watch: {
    questions: {
      handler(value) {
        if (value && value.length) {
          // eslint-disable-next-line
          for (const property in value) {
            if (value[property].qid === this.startFromQuestion) {
              this.currentIndex = +property;
            }
          }

          this.currentQuestion = value[this.currentIndex];
        }
      },
      immediate: true,
    },
  },
  computed: {
    getTickSize() {
      return Math.abs(Math.round(this.answer) * 5) + 60;
    },
    ...mapGetters({
      getProfile: 'auth/getProfile',
    }),
    isAuth() {
      return this.getProfile.token;
    },
  },
  methods: {
    closeWarningModalModal(value) {
      this.isOpenOwnFeedBackErrorModal = value;
    },
    updateAnswer(answer) {
      this.answer = answer;
    },
    async saveAnswerByPersonalityTest() {
      if (!localStorage.getItem('uniqueId')) {
        localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
          * Math.floor(Math.random() * Date.now()) * Math.random())}`);
      }

      let requestParam = '?option=';
      if (this.$route.query.option) {
        requestParam += this.$route.query.option;
      } else {
        requestParam = '';
      }

      return this.$store.dispatch('invitation/setPersonalityTest',
        {
          formData: this.formData,
          id: this.$route.params.id,
          uniqueId: localStorage.getItem('uniqueId'),
          requestParam,
        }).then(() => {
        this.$router.push({
          name: 'main',
        });
      }).catch(() => {
        this.isOpenOwnFeedBackErrorModal = true;
        this.errorMessage = 'You cannot complete your own feedback. '
          + 'Please send it to contacts that know you.';
        this.currentQuestion = true;
        this.completedResult = false;
      });
    },
    clickToSlider() {
      this.clickedOnSlider = true;
    },
    cancelNextQuestion() {
      if (this.debouncedHandler) {
        this.debouncedHandler.cancel();
      }
    },
    onSelectedQuestion(answer) {
      this.debouncedHandler = debounce(async () => {
        this.formData[this.questions[this.currentIndex].qid] = answer;

        if (!this.crowdSurvey) {
          this.generateAnonymous();
          await this.$api.questionnaire.savePartialAnswers({ [this.questions[this.currentIndex].qid]: answer }, localStorage.getItem('uniqueId'));
          await this.$api.userBehaveSite
            .saveSiteEvent(userBehave.answerToQuestion + this.currentIndex);
        }

        if (this.isPersonalityTest()) {
          await this.$api.userBehaveSite
            .saveSiteEvent(userBehave.answerToQuestionCrowd + this.currentIndex);
        }

        this.currentIndex += 1;
        if (this.currentIndex >= this.questions.length) {
          this.completedResult = false;
          setTimeout(() => {
            this.nextQuestion();
          }, 2000);
        } else {
          this.nextQuestion();
        }
      }, 2000);
      return this.debouncedHandler();
    },
    async nextQuestion() {
      this.answer = 0;
      if (this.currentIndex >= this.questions.length) {
        // this.$emit('saveAnswer', this.formData);
        this.currentIndex = 0;
        this.currentQuestion = null;
        this.loading = true;
        await this.saveAnswer();
        this.loading = false;
      } else {
        this.currentQuestion = this.questions[this.currentIndex];
        this.clickedOnSlider = false;
      }
    },

    isPersonalityTest() {
      return this.$route.meta.invitationType === process.env.QUESTIONNAIRE_ID;
    },
    async saveAnswer() {
      this.completedColleaguesTest();

      if (this.isPersonalityTest()) {
        return this.saveAnswerByPersonalityTest();
      }

      if (!this.isAuth && !this.crowdSurvey) {
        this.generateAnonymous();

        return this.$api.questionnaire.saveAnswerFreeVersionNew(this.formData, localStorage.getItem('uniqueId'))
          .then(() => {
            // this.goToNextStep();
            this.$store.dispatch('showComponent/gotToStep2');
            const profile = {
              ...this.getProfile,
              completedQuestionnaires: [process.env.QUESTIONNAIRE_ID],
            };
            this.$store.dispatch('auth/updateProfile', profile).then(() => {
              this.$store.dispatch('showComponent/gotToStep2');
            });
          }).catch(() => {
            this.$store.dispatch('showComponent/gotToStep2');
          });
      }

      return this.$api.questionnaire.saveAnswer(this.formData)
        .then(() => {
          localStorage.removeItem('uniqueId');
          const profile = {
            ...this.getProfile,
            completedQuestionnaires: [...this.getProfile.completedQuestionnaires,
              process.env.QUESTIONNAIRE_ID],
          };

          this.$store.dispatch('auth/updateProfile', profile).then(() => {
            this.$store.dispatch('showComponent/gotToStep2');
          });
          this.$store.dispatch('showComponent/gotToStep2');
        }).catch(() => {
          this.$store.dispatch('showComponent/gotToStep4');
        });
    },
    completedColleaguesTest() {
      this.$gtag.event('Click test', {
        [window.location.pathname.split('/').pop()]: 'Complete test', // eslint-disable-line
        'value': 1,                                                             // eslint-disable-line
      });
    },
    generateAnonymous() {
      if (!localStorage.getItem('uniqueId')) {
        localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
          * Math.floor(Math.random() * Date.now()) * Math.random())}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-center {
  height: 125px;
}
.question-content {
  box-sizing: border-box;
  overflow-y: hidden;
}
::v-deep.question {

  @media (max-width: 330px) {
    .question__select-result {
      padding-top: 10px;
      .v-slider__thumb-label {
        bottom: 100px !important;
      }
      .info-use {
        margin-top: 0 !important;
      }
    }
    .question__content {
      font-size: 22px !important;
    }
  }
  @media (max-width: 380px) {
    .question__select-result {
      padding-top: 10px;
      .v-slider__thumb-label {
        bottom: 100px !important;
      }
      .info-use {
        margin-top: 0 !important;
      }
    }
    .question__content {
      font-size: 28px;
    }
  }
  @media (max-height: 600px) {
    .question__select-result {
      .info-use {
        margin-top: -10px !important;
      }
    }
  }
  .question__select-result {
    .info-use {
      font-family: $newDefaultFont;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #222222;
    }
    .info-text {
      opacity: 0.3;
      margin-top: 21px;
    }
  }
}
</style>
