<template>
  <div class="progress-bar d-flex align-center">
    <div class="progress-bar__value">
      <div
        class="progress-bar__current-value"
        :style="{ width: getPercentByStep, backgroundColor: '#7811C9' }"
      ></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'QuestionProgressBar',
  props: {
    currentIndex: {
      type: Number,
    },
    lastQuestionNumber: {
      type: Number,
    },
  },
  computed: {
    getPercentByStep() {
      return `${this.currentIndex * 100 / this.lastQuestionNumber}%`;
    },
  },
};
</script>


<style lang="scss" scoped>
  .progress-bar {
    .progress-bar__value {
      width: 100%;
      height: 5px;
      background-color: #ffffff;
    }
    .progress-bar__current-value {
      background-color: $progressBarBg;
      height: 100%;
    }
    .progress-bar__info {
      margin-left: 20px;
    }
  }
</style>
