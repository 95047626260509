<template>
  <Content class="current-question">
    <div v-html="question" class="question__content text-center"></div>
  </Content>
</template>

<script>

import sound from '@assets/sound.svg';

export default {
  name: 'Question',
  data() {
    return {
      sound,
    };
  },
  props: {
    question: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
  .sound {
    margin-top: 28px;
  }
  .current-question {
    height: 90px;
    margin-bottom: 126px;
    margin-top: 16%;
    max-height: 150px;
    color: $txtColorNewDesignMain;
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 36px;
    padding-left: 35px;
    padding-right: 35px;
    overflow-wrap: break-word;
  }
  @media (max-height: 570px) {
    .current-question {
      margin-bottom: 57px;
      margin-top: 30px;
      max-height: 150px;
      color: $txtColorNewDesignMain;
    }
  }
  @media (min-width: 480px) {
    .current-question {
      height: 150px;
    }
  }
</style>
